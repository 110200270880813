import { createBrowserRouter, Navigate } from 'react-router-dom';

import contactsStore from 'store/contactsStore';
import propertyTypes from './propertyTypes';
import newsStore from 'store/newsStore';
import services from './services';
import privacy from './privacy';
import Layout from './layout';
import about from './about';
import terms from './terms';
import home from './home';
import news from './news';
import wip from './wip';

const loader = async () => {
  try {
    await Promise.all([contactsStore.loadData(), newsStore.loadList()]);
    return true;
  } catch (e) {
    return false;
  }
};

const layoutRoutes = {
  path: '/',
  element: <Layout />,
  errorElement: <Navigate to="/" />,
  children: [propertyTypes, services, news, about, home],
  loader
};

const routes = [wip, terms, privacy, layoutRoutes];

export const router = createBrowserRouter(routes);
