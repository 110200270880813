import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { links } from 'config';
import moment from 'moment';

import Background from 'components/Background';

import { ReactComponent as Figure } from 'assets/img/wip/figure.svg';
import backgroundVideo from 'assets/video/background.mp4';
import background from 'assets/img/background.webp';
import logo from 'assets/img/wip/logo.svg';

const Page = observer(() => {
  const [isProgressFinished, setIsProgressFinished] = useState(false);
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const [percentValue, setPercentValue] = useState(0);
  const [dateValue, setDateValue] = useState(new Date('2024-05-01'));

  const progressStart = new Date('2024-05-01').getTime();
  const progressEnd = new Date('2024-10-15').getTime();

  const progress = ((Date.now() - progressStart) / (progressEnd - progressStart)).toFixed(2);

  const progressAnimationDuration = 2000;

  useEffect(() => {
    const fontPromise = new Promise((resolve) => {
      import('@fontsource/inter/files/inter-latin-400-normal.woff').then(resolve);
    });

    const previewPromise = new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.src = background;
    });

    Promise.all([fontPromise, previewPromise]).then(() => setIsAssetsLoaded(true));
  }, []);

  useEffect(() => {
    if (!isAssetsLoaded) return;

    const figureRect = document.querySelector('.wip-page__progress-figure rect');
    const digits = document.querySelector('.wip-page__progress-digits');
    const hr = document.querySelector('.wip-page__progress-hr');

    const progressInPercents = `${progress * 100}%`;
    figureRect.style.width = progressInPercents;
    digits.style.left = progressInPercents;
    hr.style.left = progressInPercents;

    setTimeout(() => setIsProgressFinished(true), progressAnimationDuration);
  }, [isAssetsLoaded]);

  useEffect(() => {
    if (percentValue < progress * 100 - 1 && Number(progress) !== 0) {
      const delay = progressAnimationDuration / (progress * 100);
      setTimeout(() => {
        setPercentValue(percentValue + 1);
      }, delay);
    }
  }, [percentValue]);

  useEffect(() => {
    if (dateValue.getDate() !== new Date().getDate()) {
      const delay = progressAnimationDuration / new Date().getDate();
      setTimeout(() => {
        setDateValue(new Date(dateValue.setDate(dateValue.getDate() + 1)));
      }, delay);
    }
  }, [dateValue]);

  return (
    <div
      className={`wip-page ${isAssetsLoaded ? 'wip-page--assets-loaded' : ''} ${isVideoLoaded && isProgressFinished ? 'wip-page--loaded' : ''}`}
    >
      <Background
        onVideoLoad={() => setIsVideoLoaded(true)}
        videoSrc={backgroundVideo}
        imgSrc={background}
        restartSecond={16}
      />

      <main className="wip-page__content">
        <img className="wip-page__logo" src={logo} alt="logo" />
        <div className="wip-page__progress">
          <div className="wip-page__progress-digits">
            <span className="wip-page__progress-digits-percent">{percentValue}%</span>
            <span className="wip-page__progress-digits-date">
              {moment(dateValue).format(`DD.MM.YY`)}
            </span>
          </div>
          <hr className="wip-page__progress-hr" />
          <Figure className="wip-page__progress-figure" />
        </div>
        <p className="wip-page__disclaimer">OUR NEW Website Is COMING SOON</p>
        <div className="wip-page__contacts">
          <a
            className="wip-page__contacts-whatsapp"
            href={`https://wa.me/${links.trimTel()}`}
            rel="noreferrer"
            target="_blank"
          >
            <span>WhatsApp</span>
          </a>
          <a
            className="wip-page__contacts-phone"
            href={`tel: ${links.trimTel()}`}
            rel="noreferrer"
            target="_blank"
          >
            <span>{links.tel}</span>
          </a>
        </div>
        <div className="wip-page__location">
          <a href={links.map} target="_blank" rel="noreferrer">
            <div className="wip-page__location-link">Location</div>
            <p className="wip-page__location-info">
              <span>UAE - DUBAI - DUBAI INTERNET CITY</span>
              <span>SHEIKH ZAYED RD EMAAR BUSINESS PARK BUILDING 4 - OFFICE 109</span>
            </p>
          </a>
        </div>
        <p className="wip-page__rights-desktop">2024 © ZILA GREEN REAL ESTATE LLC</p>
      </main>
      <div className="wip-page__rights-overlay">
        <p className="wip-page__rights">2024 © ZILA GREEN REAL ESTATE LLC</p>
      </div>
    </div>
  );
});

export default Page;
