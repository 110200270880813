import PropTypes from 'prop-types';
import { useEffect } from 'react';

const ProgressBar = ({ activeIndex }) => {
  useEffect(() => {
    const indicator = document.querySelector('.progress-bar__indicator');

    indicator.classList.remove('progress-bar__indicator--animated');
    setTimeout(() => indicator.classList.add('progress-bar__indicator--animated'), 50);
  }, [activeIndex]);

  return (
    <div className="progress-bar">
      <div className="progress-bar__indicator" />
      <div className="progress-bar__track" />
    </div>
  );
};

ProgressBar.propTypes = {
  activeIndex: PropTypes.number
};

export default ProgressBar;
