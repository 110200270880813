import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-number-input';

const PhoneSubmit = ({ value, onInput, errors }) => {
  const isError = () => errors.find((error) => error === 'phone');

  const onChange = (value) => onInput('phone', value);

  return (
    <label className={`phone-submit ${isError() ? 'phone-submit--error' : ''}`}>
      <PhoneInput
        countryCallingCodeEditable={false}
        international
        defaultCountry="AE"
        value={value}
        onChange={onChange}
      />
      <button type="submit">Get Catalog</button>
    </label>
  );
};

PhoneSubmit.propTypes = {
  onInput: PropTypes.func,
  errors: PropTypes.array,
  value: PropTypes.string
};

export default PhoneSubmit;
