import interfaceStore, { interfaceColors } from 'store/interfaceStore';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import interfaceLinks from 'store/interfaceLinks';
import { useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Mousewheel } from 'swiper';

import Background from 'components/Background';

const Page = observer(() => {
  const { services } = useLoaderData();
  const swiperRef = useRef(null);
  const ref = useRef(null);

  const [currentSection, setCurrentSection] = useState(1);

  const getServiceBackgroundId = (id) => {
    let className = '';
    if (id < currentSection) className += ' background--behind';
    if (id === currentSection) className += ' background--visible';
    return className;
  };

  useEffect(() => {
    interfaceStore.setRightLink({
      ...interfaceLinks.home,
      href: `/${interfaceLinks.services.href}`
    });

    interfaceStore.setCurrentColor(interfaceColors.WHITE);
    return () => {
      interfaceStore.setCurrentColor(interfaceColors.BLACK);
    };
  }, []);

  return (
    <main className="page services-page" ref={ref}>
      <div className="services-page__background-images">
        {services.map((service) => (
          <Background
            imgSrc={service.img}
            key={service.id}
            className={getServiceBackgroundId(service.id)}
          />
        ))}
      </div>

      <Swiper
        className="services-page__sections"
        slidesPerView={1}
        spaceBetween={0}
        direction={'vertical'}
        mousewheel={{ enabled: true, forceToAxis: true, releaseOnEdges: true, thresholdDelta: 30 }}
        ref={swiperRef}
        modules={[Mousewheel]}
        onActiveIndexChange={(data) => setCurrentSection(data.activeIndex + 1)}
        speed={500}
      >
        {services.map((service) => (
          <SwiperSlide
            key={service.id}
            className={`services-page__section services-page__section--${service.id}`}
          >
            <h1 className="services-page__section-title">{service.title}</h1>
            <p className="services-page__section-description">{service.description}</p>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="services-page__navigation">
        <div className="services-page__navigation-dots">
          {services.map((service, index) => (
            <button
              key={service.id}
              className={service.id === currentSection ? 'active' : ''}
              onClick={() => swiperRef.current.swiper.slideTo(index)}
            ></button>
          ))}
        </div>
        <div
          className={`services-page__navigation-indicator services-page__navigation-indicator--${currentSection}`}
        >
          <div className="services-page__navigation-hr"></div>
          <div className="services-page__navigation-number">0{currentSection}</div>
        </div>
      </div>
    </main>
  );
});

export default Page;
