import PropTypes from 'prop-types';

const FormWrapper = ({ onSubmit, children }) => {
  const submitForm = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={submitForm} className="form">
      {children}
    </form>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func
};

export default FormWrapper;
