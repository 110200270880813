import { makeAutoObservable } from 'mobx';
import { API_URL } from 'config';

class NewsStore {
  news = [];

  constructor() {
    makeAutoObservable(this);
  }

  setNews(news) {
    this.news = news;
  }

  async loadList() {
    const url = `${API_URL}/news-list?populate=*&sort[0]=date:desc&pagination[page]=1&pagination[pageSize]=6`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to load news');
    }

    const result = await response.json();

    this.setNews(result.data);

    return result.data;
  }

  async loadById(id) {
    const url = `${API_URL}/news-list/${id}?populate=*`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to load news item');
    }

    return await response.json();
  }
}

export default new NewsStore();
