import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { useEffect, useRef, useState } from 'react';
import interfaceStore from 'store/interfaceStore';
import contactsStore from 'store/contactsStore';

const Contacts = () => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      const rightLink = getRightLink(ref.current, interfaceLinks.upside);

      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }

      setVisible(!!rightLink);
    };

    onScroll();

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <section
      className={`contacts-section ${visible ? 'contacts-section--visible' : ''}`}
      id="contacts-section"
      ref={ref}
    >
      <div className="contacts-section__sticky">
        <h1 className="contacts-section__title">Contact us</h1>
        <div className="contacts-section__list">
          <div className="contact-elem">
            <h2 className="contact-elem__title">Location</h2>
            <div className="contact-elem__links">
              <a href={contactsStore.data.map} rel="noreferrer" target="_blank">
                {contactsStore.data.address}
              </a>
            </div>
          </div>

          <div className="contact-elem">
            <h2 className="contact-elem__title">Phone, Mail</h2>
            <div className="contact-elem__links">
              <a href={`tel: ${contactsStore.data.phone}`} rel="noreferrer" target="_blank">
                {contactsStore.data.phone}
              </a>
              <a href={`mailto: ${contactsStore.data.mail}`} rel="noreferrer" target="_blank">
                {contactsStore.data.mail}
              </a>
            </div>
          </div>

          <div className="contact-elem">
            <h2 className="contact-elem__title">Messengers</h2>
            <div className="contact-elem__links">
              <a href={contactsStore.whatsapp} rel="noreferrer" target="_blank">
                WhatsApp
              </a>
              <a href={contactsStore.telegram} rel="noreferrer" target="_blank">
                Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
