import { isTouchScreen, lerp, vh, vw } from 'utils/helpers';
import { Suspense, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import confirm from 'utils/confirm';
import { router } from 'router';

const App = () => {
  const getLocalCookieState = () => {
    return !!localStorage.getItem('cookieState');
  };

  const [cookieState, setCookieState] = useState(getLocalCookieState());

  const setLocalCookieState = (value) => {
    return localStorage.setItem('cookieState', value);
  };

  const onResize = () => {
    window.requestAnimationFrame(onResize);

    const root = document.querySelector('#root');
    root.style.setProperty('--vh', `${vh()}px`);
    root.style.setProperty('--vw', `${vw()}px`);
  };

  useEffect(() => {
    onResize();

    let rendering = false;
    window.defaultLerpDuration = 0.1;
    window.lerpDuration = window.defaultLerpDuration;
    window.sy = 0;
    window.dy = 0;

    const render = () => {
      rendering = true;

      window.requestAnimationFrame(render);

      if (isTouchScreen()) return;

      window.dy = lerp(window.dy, window.sy, window.lerpDuration);
      window.dy = Math.floor(window.dy * 100) / 100;

      window.scrollTo({ top: window.dy });

      const scrollEnd = Math.abs(window.sy - window.scrollY) < 2;

      if (!scrollEnd) return;

      window.lerpDuration = window.defaultLerpDuration;
    };

    const onWheel = (event) => {
      const scrollableElements = [
        '.interface__menu',
        '.property-types-page',
        '.services-page',
        '.news-modal'
      ];

      if (scrollableElements.some((selector) => event.target.closest(selector))) return;

      if (Math.abs(event.deltaY) > 0) {
        event.preventDefault();
      }

      const max = document.body.scrollHeight - window.innerHeight;
      window.sy = Math.min(max, Math.max(window.sy + event.deltaY, 0));
    };

    document.querySelector('#root').addEventListener('wheel', onWheel, true);

    window.addEventListener('resize', () => {
      if (rendering) return;
      render();
    });

    render();
  }, []);

  useEffect(() => {
    if (cookieState) return;

    confirm({
      cancelText: 'Reject all',
      submitText: 'Accept all',
      title: 'We value your privacy.',
      text: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.'
    }).then((result) => {
      setLocalCookieState(result);
      setCookieState(result);
    });
  }, [cookieState]);

  return (
    <div className="application">
      <Suspense fallback={<></>}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};

export default App;
