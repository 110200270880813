import { scrollProgress, mouseParallax, dpx, tpx, mpx, isTouchScreen } from 'utils/helpers';
import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { useEffect, useRef, useState } from 'react';
import interfaceStore from 'store/interfaceStore';

import Background from 'components/Background';

import backgroundVideo from 'assets/video/background-blur.mp4';
import background from 'assets/img/background.webp';

import image1 from 'assets/img/main/image-1.webp';
import image2 from 'assets/img/main/image-2.webp';
import image3 from 'assets/img/main/image-3.webp';
import image4 from 'assets/img/main/image-4.webp';
import image5 from 'assets/img/main/image-5.webp';
import image6 from 'assets/img/main/image-6.webp';
import image7 from 'assets/img/main/image-7.webp';
import image8 from 'assets/img/main/image-8.webp';
import image9 from 'assets/img/main/image-9.webp';
import image10 from 'assets/img/main/image-10.webp';

import { ReactComponent as ZFillIcon } from 'assets/svg/zila/z-fill.svg';
import { ReactComponent as ZOutlineIcon } from 'assets/svg/zila/z-outline.svg';
import { ReactComponent as IFillIcon } from 'assets/svg/zila/i-fill.svg';
import { ReactComponent as IOutlineIcon } from 'assets/svg/zila/i-outline.svg';
import { ReactComponent as LFillIcon } from 'assets/svg/zila/l-fill.svg';
import { ReactComponent as LOutlineIcon } from 'assets/svg/zila/l-outline.svg';
import { ReactComponent as AFillIcon } from 'assets/svg/zila/a-fill.svg';
import { ReactComponent as AOutlineIcon } from 'assets/svg/zila/a-outline.svg';

const images = [
  {
    element: () => document.querySelector('.image--1'),
    minMax: () => [dpx(-674), dpx(-3044)],
    minMaxTablet: () => [tpx(-52), tpx(-723)],
    minMaxMobile: () => [mpx(-52), mpx(-723)],
    parallax: [-20, -2],
    image: image1
  },
  {
    element: () => document.querySelector('.image--2'),
    minMax: () => [dpx(-364), dpx(-2734)],
    minMaxTablet: () => [tpx(103), tpx(-561)],
    minMaxMobile: () => [mpx(103), mpx(-561)],
    parallax: [5, -30],
    image: image2
  },
  {
    element: () => document.querySelector('.image--3'),
    minMax: () => [dpx(155), dpx(-2215)],
    minMaxTablet: () => [tpx(363), tpx(-308)],
    minMaxMobile: () => [mpx(363), mpx(-308)],
    parallax: [20, 5],
    image: image3
  },
  {
    element: () => document.querySelector('.image--4'),
    minMax: () => [dpx(566), dpx(-1804)],
    minMaxTablet: () => [tpx(568), tpx(-103)],
    minMaxMobile: () => [mpx(568), mpx(-103)],
    parallax: [-5, 10],
    image: image4
  },
  {
    element: () => document.querySelector('.image--5'),
    minMax: () => [dpx(876), dpx(-1494)],
    minMaxTablet: () => [tpx(723), tpx(52)],
    minMaxMobile: () => [mpx(723), mpx(52)],
    parallax: [-30, 20],
    image: image5
  },
  {
    element: () => document.querySelector('.image--6'),
    minMax: () => [dpx(1496), dpx(-874)],
    minMaxTablet: () => [tpx(-52), tpx(-723)],
    minMaxMobile: () => [mpx(-52), mpx(-723)],
    parallax: [-20, -2],
    image: image6
  },
  {
    element: () => document.querySelector('.image--7'),
    minMax: () => [dpx(1806), dpx(-564)],
    minMaxTablet: () => [tpx(103), tpx(-561)],
    minMaxMobile: () => [mpx(103), mpx(-561)],
    parallax: [5, -30],
    image: image7
  },
  {
    element: () => document.querySelector('.image--8'),
    minMax: () => [dpx(2325), dpx(-45)],
    minMaxTablet: () => [tpx(363), tpx(-308)],
    minMaxMobile: () => [mpx(363), mpx(-308)],
    parallax: [20, 5],
    image: image8
  },
  {
    element: () => document.querySelector('.image--9'),
    minMax: () => [dpx(2736), dpx(366)],
    minMaxTablet: () => [tpx(568), tpx(-103)],
    minMaxMobile: () => [mpx(568), mpx(-103)],
    parallax: [-5, 10],
    image: image9
  },
  {
    element: () => document.querySelector('.image--10'),
    minMax: () => [dpx(3046), dpx(676)],
    minMaxTablet: () => [tpx(723), tpx(52)],
    minMaxMobile: () => [mpx(723), mpx(52)],
    parallax: [-30, 20],
    image: image10
  }
];

const letters = [
  {
    elements: () => document.querySelectorAll('.letter--z'),
    minMax: () => [window.innerWidth / 2 + dpx(200) * 1, dpx(-403)],
    minMaxTablet: () => [window.innerWidth / 2 + tpx(66) * 1, tpx(-95)],
    minMaxMobile: () => [window.innerWidth / 2 + mpx(66) * 1, mpx(-95)]
  },
  {
    elements: () => document.querySelectorAll('.letter--i'),
    minMax: () => [window.innerWidth / 2 + dpx(200) * 3, dpx(-158)],
    minMaxTablet: () => [window.innerWidth / 2 + tpx(66) * 3, tpx(-37)],
    minMaxMobile: () => [window.innerWidth / 2 + mpx(66) * 3, mpx(-37)]
  },
  {
    elements: () => document.querySelectorAll('.letter--l'),
    minMax: () => [window.innerWidth / 2 + dpx(200) * 5, dpx(63)],
    minMaxTablet: () => [window.innerWidth / 2 + tpx(66) * 5, tpx(15)],
    minMaxMobile: () => [window.innerWidth / 2 + mpx(66) * 5, mpx(15)]
  },
  {
    elements: () => document.querySelectorAll('.letter--a'),
    minMax: () => [window.innerWidth / 2 + dpx(200) * 7, dpx(369)],
    minMaxTablet: () => [window.innerWidth / 2 + tpx(66) * 7, tpx(87)],
    minMaxMobile: () => [window.innerWidth / 2 + mpx(66) * 7, mpx(87)]
  }
];

const imagesAnimationSteps = {
  IN: 'in',
  OUT: 'out'
};

const Main = () => {
  const ref = useRef(null);

  const [imagesAnimationStep, setImagesAnimationStep] = useState('');

  useEffect(() => {
    const background = ref.current.querySelector('.background');
    const sticky = document.querySelector('.main-section__sticky');

    const fullRange = scrollProgress(ref.current, [0.0, 1.0]);
    const mainRange = scrollProgress(ref.current, [0.0, 0.75]);
    const logoScaleRange = scrollProgress(ref.current, [0.75, 1.0]);
    const backgroundRange1 = scrollProgress(ref.current, [0.5, 1.0]);
    const backgroundRange2 = scrollProgress(ref.current, [1.25, 1.5]);

    const onScroll = () => {
      const totalProgress = fullRange.getProgress(false);
      const logoScale = logoScaleRange.interpolate([1.0, 0.7]);
      const backgroundOpacity =
        totalProgress > 1.25
          ? backgroundRange2.interpolate([0.1, 0.0])
          : backgroundRange1.interpolate([0.0, 0.1]);

      sticky.style.setProperty('--scale', logoScale);
      background.style.setProperty('--opacity', backgroundOpacity);

      if (totalProgress >= 0.75) {
        setImagesAnimationStep(imagesAnimationSteps.OUT);
      } else {
        setImagesAnimationStep(imagesAnimationSteps.IN);
      }

      // Desktop
      letters.forEach((letter) => {
        letter.elements().forEach((element) => {
          element.style.setProperty('--tablet-x', mainRange.interpolate(letter.minMaxTablet()));
          element.style.setProperty('--mobile-x', mainRange.interpolate(letter.minMaxMobile()));
          element.style.setProperty('--x', mainRange.interpolate(letter.minMax()));
        });
      });

      images.forEach((image) => {
        const element = image.element();

        element?.style.setProperty('--tablet-x', mainRange.interpolate(image.minMaxTablet()));
        element?.style.setProperty('--mobile-x', mainRange.interpolate(image.minMaxMobile()));
        element?.style.setProperty('--x', mainRange.interpolate(image.minMax()));
        element?.style.setProperty('--progress', mainRange.getProgress());
      });

      // Links
      const rightLink = getRightLink(ref.current, interfaceLinks.whoWeAre);
      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }
    };

    const onPointerMove = (event) => {
      if (isTouchScreen()) return;

      const [x, y] = mouseParallax(event);
      images.forEach((image) => {
        const element = image.element();

        element?.style.setProperty('--dx', x * image.parallax[0]);
        element?.style.setProperty('--dy', y * image.parallax[1]);
      });
    };

    onScroll();

    // TODO: Add window resize handler
    window.addEventListener('scroll', onScroll);
    window.addEventListener('pointermove', onPointerMove);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('pointermove', onPointerMove);
    };
  }, []);

  return (
    <section className="main-section" id="main-section" ref={ref}>
      <Background imgSrc={background} videoSrc={backgroundVideo} restartSecond={16} />

      <div className="main-section__sticky">
        <div className="main-section__letters">
          <div className="letter letter--fill letter--z">
            <ZFillIcon />
          </div>
          <div className="letter letter--fill letter--i">
            <IFillIcon />
          </div>
          <div className="letter letter--fill letter--l">
            <LFillIcon />
          </div>
          <div className="letter letter--fill letter--a">
            <AFillIcon />
          </div>
        </div>

        <div className="main-section__letters main-section__letters--outline">
          <div className="letter letter--outline letter--z">
            <ZOutlineIcon />
          </div>
          <div className="letter letter--outline letter--i">
            <IOutlineIcon />
          </div>
          <div className="letter letter--outline letter--l">
            <LOutlineIcon />
          </div>
          <div className="letter letter--outline letter--a">
            <AOutlineIcon />
          </div>
        </div>

        {images.map(({ image }, index) => (
          <div key={index} className={`image image--${index + 1} image--${imagesAnimationStep}`}>
            <img src={image} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Main;
