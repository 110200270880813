import { API_URL } from 'config';

export const headers = {
  'Content-Type': 'application/json'
};

export const submit = async (form) => {
  const url = `${API_URL}/requests`;

  const data = {
    newness: form.newness,
    bedrooms: form.bedrooms,
    propertyType: form.propertyType,
    phone: form.phone
  };

  if (form.price) {
    data.currency = form.price.currency;
    data.min = form.price.min;
    data.max = form.price.max;
  }

  const body = JSON.stringify({ data });
  const method = 'POST';

  const params = { method, body, headers };

  const response = await fetch(url, params);

  if (!response.ok) {
    throw new Error('Failed to create request');
  }

  return await response.json();
};
