import Page from 'pages/About';

export default {
  path: '/about',
  children: [
    {
      index: true,
      element: <Page />
    }
  ]
};
