import { observer } from 'mobx-react';

import Services from './services';
import Contacts from './contacts';
import About from './about';
import Types from './types';
import Main from './main';
import News from './news';
import Form from './form';

const Page = observer(() => {
  return (
    <main className="home-page home-page--loaded">
      <Main />
      <About />
      <Services />
      <Types />
      <News />
      <Form />
      <Contacts />
    </main>
  );
});

export default Page;
