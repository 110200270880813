import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import propertyTypes from 'lib/propertyTypes';

import arrowDown from 'assets/svg/arrow-down.svg';

const PropertyTypeSelect = ({ value, onInput, errors }) => {
  const [isOpened, setIsOpened] = useState(false);

  const ref = useRef(null);

  const toggleList = () => setIsOpened((prev) => !prev);
  const closeList = () => setIsOpened(false);

  const isError = () => errors.find((error) => error === 'propertyType');

  const onSelectOption = (propertType) => {
    onInput('propertyType', propertType);
    closeList();
  };

  useEffect(() => {
    const onClick = (event) => {
      if (!ref.current.contains(event.target)) closeList();
    };

    if (isOpened) window.addEventListener('click', onClick);

    return () => window.removeEventListener('click', onClick);
  }, [isOpened]);

  return (
    <div
      className={`select ${isOpened ? 'select--opened' : ''} ${isError() ? 'select--error' : ''}`}
      ref={ref}
    >
      <button className="select__button" onClick={toggleList} type="button">
        <span>{value || 'PROPERTY TYPE'}</span>
        <img src={arrowDown} alt="arrow-down" />
      </button>

      <div className="select__list">
        {propertyTypes.map((type) => (
          <button
            type="button"
            className={`select__list-option ${type.name === value ? 'active' : ''}`}
            key={type.id}
            onClick={() => onSelectOption(type.name)}
          >
            {type.name}
          </button>
        ))}
      </div>
    </div>
  );
};

PropertyTypeSelect.propTypes = {
  onInput: PropTypes.func,
  errors: PropTypes.array,
  value: PropTypes.string
};

export default PropertyTypeSelect;
