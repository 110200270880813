import { initialMin, initialMax, initialCurrency } from 'lib/formRangeData';
import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { useEffect, useRef, useState } from 'react';
import interfaceStore from 'store/interfaceStore';
import { scrollProgress } from 'utils/helpers';
import confirm from 'utils/confirm';
import { submit } from 'utils/api';

import FormNewness, { newnessTypes } from 'components/form/FormNewness';
import PropertyTypeSelect from 'components/form/PropertyTypeSelect';
import FormBedrooms from 'components/form/FormBedrooms';
import FormWrapper from 'components/form/FormWrapper';
import PhoneSubmit from 'components/form/PhoneSubmit';
import PriceRange from 'components/form/PriceRange';

const initialForm = () => ({
  newness: newnessTypes[0],
  bedrooms: 1,
  propertyType: '',
  phone: '',
  price: {
    currency: initialCurrency,
    min: initialMin,
    max: initialMax
  }
});

const Form = () => {
  const ref = useRef(null);

  const [errors, setErrors] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState(initialForm());

  useEffect(() => {
    const filteredErrors = errors.filter((error) => form[error] === '');
    setErrors(filteredErrors);
  }, [form]);

  const onInput = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const onSubmit = async () => {
    const submitErrors = [];

    if (form.phone.length === 0) submitErrors.push('phone');
    if (form.propertyType.length === 0) submitErrors.push('propertyType');

    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return;
    }

    await submit(form)
      .then(() => {
        setForm(initialForm());

        return confirm({
          cancelText: null,
          submitText: 'Close',
          title: 'Message Sent!',
          text: "Thank you! We've received your phone number. We'll send you the property catalog shortly and get in touch to clarify the details."
        });
      })
      .catch((error) => {
        return confirm({
          cancelText: null,
          submitText: 'Close',
          title: error.name,
          text: error.message
        });
      });
  };

  useEffect(() => {
    const fullRange = scrollProgress(ref.current, [0.0, 1.0]);

    const onScroll = () => {
      const rightLink = getRightLink(ref.current, interfaceLinks.contactUs);

      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }

      setVisible(!!rightLink);

      if (fullRange.getProgress(false) <= 1) {
        interfaceStore.setLeftLinks([interfaceLinks.whatsapp, interfaceLinks.telegram]);
        interfaceStore.setMiddleLinks([interfaceLinks.contactUs]);
        return;
      }
      interfaceStore.setMiddleLinks([interfaceLinks.terms, interfaceLinks.privacy]);
      interfaceStore.setLeftLinks([interfaceLinks.rights]);
    };

    onScroll();

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);

      interfaceStore.setLeftLinks([interfaceLinks.whatsapp, interfaceLinks.telegram]);
      interfaceStore.setMiddleLinks([interfaceLinks.contactUs]);
    };
  }, []);

  return (
    <section
      className={`form-section ${visible ? 'form-section--visible' : ''}`}
      id="form-section"
      ref={ref}
    >
      <div className="form-section__background" />

      <div className="form-section__sticky">
        <FormWrapper onSubmit={onSubmit}>
          <FormNewness value={form.newness} onInput={onInput} />
          <PropertyTypeSelect value={form.propertyType} onInput={onInput} errors={errors} />
          <FormBedrooms value={form.bedrooms} onInput={onInput} />
          <PriceRange value={form.price} onInput={onInput} />

          <PhoneSubmit value={form.phone} onInput={onInput} errors={errors} />
        </FormWrapper>
      </div>
    </section>
  );
};

export default Form;
