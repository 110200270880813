import Page from 'pages/Services';
import { loader } from './loader';

export default {
  path: '/services',
  children: [
    {
      index: true,
      element: <Page />,
      loader: loader
    }
  ]
};
