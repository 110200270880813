import propertyTypes from 'lib/propertyTypes';
import { redirect } from 'react-router-dom';

export const loader = async ({ request }) => {
  const typeId = Number(new URL(request.url).searchParams.get('id'));
  const currentType = propertyTypes.find(({ id }) => id === typeId);

  if (!currentType) {
    const defaultURL = '/property-types?id=1';
    return redirect(defaultURL);
  }

  return { propertyTypes, currentType, typeId };
};
