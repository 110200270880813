import { observer } from 'mobx-react';
import { Outlet } from 'react-router';

import Interface from 'components/Interface';
import ScrollToTop from 'ScrollToTop';

const Layout = observer(() => {
  return (
    <>
      <ScrollToTop />
      <Interface />
      <Outlet />
    </>
  );
});

export default Layout;
