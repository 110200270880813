import Page from 'pages/Home';

export default {
  path: '/',
  children: [
    {
      index: true,
      element: <Page />
    }
  ]
};
