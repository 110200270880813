import { Navigate, redirect } from 'react-router-dom';
import newsStore from 'store/newsStore';

import Page from 'pages/News';

const loader = async (props) => {
  try {
    return await newsStore.loadById(props.params.id);
  } catch (e) {
    redirect('/');
  }
};

export default {
  path: '/news',
  children: [
    {
      index: true,
      element: <Navigate to="/" />
    },
    {
      path: ':id',
      element: <Page />,
      loader
    }
  ]
};
