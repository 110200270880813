import { FreeMode, Scrollbar, Mousewheel, Pagination } from 'swiper';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import interfaceStore from 'store/interfaceStore';
import interfaceLinks from 'store/interfaceLinks';
import { isTouchScreen } from 'utils/helpers';
import { observer } from 'mobx-react';
import confirm from 'utils/confirm';
import { submit } from 'utils/api';

import ProgressBar from 'components/ProgressBar';
import FormWrapper from 'components/form/FormWrapper';
import PhoneSubmit from 'components/form/PhoneSubmit';

const emptyForm = () => ({ phone: '' });

const Page = observer(() => {
  const { propertyTypes, currentType, typeId } = useLoaderData();

  const navigate = useNavigate();

  const swiperRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [form, setForm] = useState(emptyForm());
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (form.phone === '') return;

    setErrors([]);
  }, [form]);

  const onInput = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const onSubmitPhone = async () => {
    if (form.phone === '') {
      setErrors(['phone']);
      return;
    }

    await submit({ ...form, propertyType: currentType.name })
      .then(() => {
        setForm(emptyForm());

        return confirm({
          cancelText: null,
          submitText: 'Close',
          title: 'Message Sent!',
          text: "Thank you! We've received your phone number. We'll send you the property catalog shortly and get in touch to clarify the details."
        });
      })
      .catch((error) => {
        return confirm({
          cancelText: null,
          submitText: 'Close',
          title: error.name,
          text: error.message
        });
      });
  };

  const onChangeType = (id) => {
    navigate(`/property-types?id=${id}`);
    setActiveIndex(0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeIndex < currentType.images.length - 1) swiperRef.current.swiper.slideNext();
      else swiperRef.current.swiper.slideTo(0);
    }, 4000);

    return () => clearTimeout(timer);
  }, [activeIndex, typeId]);

  useEffect(() => {
    interfaceStore.setRightLink({
      ...interfaceLinks.home,
      href: `/${interfaceLinks.types.href}`
    });
  }, []);

  return (
    <main className="page property-types-page">
      <div className="property-types-page__marquee">
        {new Array(2).fill('').map((_, index) => (
          <div className="property-types-page__marquee-slide" key={index}>
            {propertyTypes.map((type) => (
              <h1
                key={`${index}-${type.id}`}
                onClick={() => onChangeType(type.id)}
                className={type.id === typeId ? 'active' : ''}
              >
                {type.name}
              </h1>
            ))}
          </div>
        ))}
      </div>

      <div className="property-types-page__content">
        <ProgressBar activeIndex={activeIndex} key={`progress-${typeId}`} />

        <Swiper
          className="property-types-page__slider"
          slidesPerView={1}
          spaceBetween={20}
          touchRatio={isTouchScreen() ? 1 : 0}
          direction={'vertical'}
          key={`${typeId}-images`}
          modules={[Pagination]}
          pagination={{ clickable: !isTouchScreen() }}
          ref={swiperRef}
          onActiveIndexChange={(data) => setActiveIndex(data.activeIndex)}
        >
          {currentType.images.map((src, index) => (
            <SwiperSlide key={`${index}-${src}`}>
              <img alt="property-image" src={src} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          direction={'vertical'}
          slidesPerView={'auto'}
          freeMode={true}
          scrollbar={true}
          mousewheel={{ enabled: true, forceToAxis: true }}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          key={`${typeId}-text`}
          className="property-types-page__description"
        >
          <SwiperSlide>{currentType.description + currentType.description}</SwiperSlide>
        </Swiper>

        <FormWrapper onSubmit={onSubmitPhone}>
          <PhoneSubmit value={form.phone} onInput={onInput} errors={errors} />
        </FormWrapper>
      </div>
    </main>
  );
});

export default Page;
