import { observer } from 'mobx-react';
import { avoidDoubleCLick, dpx, isTouchScreen, scrollProgress } from 'utils/helpers';
import { useEffect, useRef, useState } from 'react';
import interfaceLinks from 'store/interfaceLinks';
import interfaceStore from 'store/interfaceStore';

import fullscreenIcon from 'assets/img/about/fullscreen-button.svg';
import preview from 'assets/img/about/preview.webp';
import aboutVideo from 'assets/video/about.mp4';

const Page = observer(() => {
  const ref = useRef(null);

  const [videoOpened, setVideoOpened] = useState(false);

  const onFullScreenChange = () => {
    const video = document.querySelector('.about-page__video video');

    video.controls = false;
    video.pause();

    setVideoOpened(false);
  };

  const onVideoClick = async () => {
    const video = document.querySelector('.about-page__video video');

    if (videoOpened) {
      return;
    }

    if (!isTouchScreen()) {
      avoidDoubleCLick(video);
    }

    video.controls = true;

    if (video.requestFullscreen) {
      await video.requestFullscreen();
    }

    setTimeout(() => {
      video.play();
    }, 50);

    setVideoOpened(true);
  };

  useEffect(() => {
    const video = document.querySelector('.about-page__video');
    const text = document.querySelector('.about-page__text');

    const fullRange = scrollProgress(ref.current);

    const videoParams = {
      videoTransition: 0.7,
      width: () => [dpx(1190), dpx(1500)],
      x: () => [window.innerWidth / 2, 0],
      intermediateWidth: () => [dpx(770), window.innerWidth - dpx(110)],
      intermediateX: function () {
        return [window.innerWidth * this.videoTransition, 0];
      }
    };

    const textParams = {
      x: () => [0, window.innerWidth / -2],
      intermediateX: () => [0, -window.innerWidth]
    };

    const onScroll = () => {
      video.style.setProperty('--width', fullRange.interpolate(videoParams.width()));
      video.style.setProperty(
        '--intermediate-width',
        fullRange.interpolate(videoParams.intermediateWidth())
      );

      video.style.setProperty('--x', fullRange.interpolate(videoParams.x()));
      video.style.setProperty(
        '--intermediate-x',
        fullRange.interpolate(videoParams.intermediateX())
      );

      text.style.setProperty('--x', fullRange.interpolate(textParams.x()));
      text.style.setProperty('--intermediate-x', fullRange.interpolate(textParams.intermediateX()));
    };

    onScroll();

    interfaceStore.setRightLink({
      ...interfaceLinks.home,
      href: `/${interfaceLinks.whoWeAre.href}`
    });

    window.addEventListener('scroll', onScroll);
    video.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      window.removeEventListener('scroll', onScroll);
      video.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  return (
    <main className="about-page" ref={ref}>
      <div className="about-page__sticky">
        <div className="about-page__text">
          <h1>About Us</h1>
          <p>
            ZILA is a distinguished boutique real estate agency based in Dubai, dedicated to
            providing exceptional, personalized services in the ever-evolving real estate market.
            <br />
            <br />
            Established by a successful and experienced broker, our agency stands out for its
            commitment to excellence, advanced technology, and strong partnerships with Dubai’s
            leading developers.
          </p>
        </div>
        <div
          className={`about-page__video ${videoOpened ? 'about-page__video--fullscreen' : ''}`}
          onClick={onVideoClick}
        >
          {!videoOpened && (
            <button className="about-page__fullscreen-button" onClick={onVideoClick}>
              <img src={fullscreenIcon} alt="fullscreen-button" />
            </button>
          )}

          <video
            loop={true}
            poster={preview}
            src={aboutVideo}
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
          />
        </div>
      </div>
    </main>
  );
});

export default Page;
