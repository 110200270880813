import Page from 'pages/WIP';

export default {
  path: '/wip',
  children: [
    {
      index: true,
      element: <Page />
    }
  ]
};
