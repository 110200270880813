export const links = {
  map: 'https://www.google.com/maps/place/ZILA+REAL+ESTATE/@25.0958963,55.1673757,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6b0024a6d411:0x6a520485a991d725!8m2!3d25.0958963!4d55.1673757!16s%2Fg%2F11vm07yvds?entry=ttu&g_ep=EgoyMDI0MTAyMS4xIKXMDSoASAFQAw%3D%3D',
  tel: '+971 56 161 9333',
  facebook: 'https://www.facebook.com/zilarealestate',
  linkedIn: 'https://www.linkedin.com/company/zilarealestate/',
  email: 'info@zila.ae',
  trimTel() {
    return this.tel.replaceAll(' ', '');
  }
};

export const API_URL = (process.env.REACT_APP_API_URL || '') + '/api';
