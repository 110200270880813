import service5 from 'assets/img/service-5.webp';
import service4 from 'assets/img/service-4.webp';
import service3 from 'assets/img/service-3.webp';
import service2 from 'assets/img/service-2.webp';
import service1 from 'assets/img/service-1.webp';

export default [
  {
    id: 1,
    title: 'Buying',
    preview: service1
  },
  {
    id: 2,
    title: 'Selling',
    preview: service2
  },
  {
    id: 3,
    title: 'Managing',
    preview: service3
  },
  {
    id: 4,
    title: 'Renting',
    preview: service4
  },
  {
    id: 5,
    title: 'Flipping',
    preview: service5
  }
];
