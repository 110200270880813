import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const Background = ({
  imgSrc,
  videoSrc,
  restartSecond,
  onVideoLoad = () => {},
  className = ''
}) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const ref = useRef(null);
  const videos = [1, 2];

  const onTimeUpdate = (event) => {
    if (!isVideoLoaded) {
      onCanPlay();
    }

    const index = Number(event.target.dataset.index);

    if (index !== currentVideoIndex) return;
    if (event.target.currentTime < restartSecond) return;
    if (ref.current.querySelector('.background__video.fading')) return;

    const nextIndex = index === 1 ? 2 : 1;
    const nextVideo = ref.current.querySelector(`.background__video--${nextIndex}`);
    nextVideo.currentTime = 0;

    event.target.classList.remove('active');
    nextVideo.classList.add('fading');

    setTimeout(() => {
      nextVideo.classList.remove('fading');
      nextVideo.classList.add('active');
    }, 1000);

    setCurrentVideoIndex(nextIndex);
  };

  const onCanPlay = () => {
    ref.current.querySelectorAll('video').forEach((video) => {
      video.classList.add('loaded');
      video.currentTime = 0;

      if (Number(video.dataset.index) !== 1) return;

      video.classList.add('active');
    });

    setIsVideoLoaded(true);
  };

  useEffect(() => {
    if (!isVideoLoaded) return;
    onVideoLoad();
  }, [isVideoLoaded]);

  return (
    <div className={`background ${className}`} ref={ref}>
      <img className="background__img" src={imgSrc} />
      {videoSrc &&
        videos.map((index) => (
          <video
            className={`background__video background__video--${index}`}
            onTimeUpdate={onTimeUpdate}
            data-index={index}
            autoPlay={true}
            src={videoSrc}
            muted="muted"
            playsInline
            loop={true}
            key={index}
          />
        ))}
    </div>
  );
};

Background.propTypes = {
  restartSecond: PropTypes.number,
  onVideoLoad: PropTypes.func,
  className: PropTypes.string,
  videoSrc: PropTypes.string,
  imgSrc: PropTypes.string
};

export default Background;
