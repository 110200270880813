import { confirmable, createConfirmation } from 'react-confirm';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const ConfirmationComponent = ({
  show = false,
  title = '',
  text = '',
  submitText = 'ОК',
  cancelText = 'Отменить',
  proceed = () => {}
}) => {
  const onCancel = () => proceed(false);

  const onSubmit = () => proceed(true);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.key !== 'Escape') return;
      onCancel();
    };

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  if (!show) return <></>;

  return (
    <div className="confirm">
      <div className="confirm-background" onClick={onCancel} />

      <div className="confirm-content">
        <div className="confirm-title">
          <h3>{title}</h3>
        </div>
        <div className="confirm-body">
          <p>{text}</p>
        </div>
        <div className="confirm-actions">
          {cancelText && (
            <button onClick={onCancel} className="confirm-button confirm-button--cancel">
              {cancelText}
            </button>
          )}
          <button onClick={onSubmit} className="confirm-button confirm-button--submit">
            {submitText}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmationComponent.propTypes = {
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  proceed: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool
};

export default createConfirmation(
  confirmable(ConfirmationComponent),
  0,
  document.querySelector('#root')
);
