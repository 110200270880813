import PropTypes from 'prop-types';
import moment from 'moment';

const NewsItem = ({ data, onClick = () => {} }) => {
  return (
    <div className="news-section__item" onClick={() => onClick(data.documentId)}>
      <div className="news-section__item__date">{moment(data.date).format(`DD.MM.YY`)}</div>
      <img className="news-section__item__preview" src={data.preview.url} />
      <p className="news-section__item__text">{data.title}</p>
      <div className="news-section__item__border" />
    </div>
  );
};

NewsItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object
};

export default NewsItem;
