import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { Swiper, SwiperSlide } from 'swiper/react';
import interfaceStore from 'store/interfaceStore';
import { scrollProgress } from 'utils/helpers';
import mainServices from 'lib/mainServices';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
  const swiperRef = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    const h2 = document.querySelector('.services-section__title h2');

    const fullRange = scrollProgress(ref.current, [0.0, 1.0]);
    const h2Range = scrollProgress(ref.current, [0.0, 0.2]);

    const onScroll = () => {
      h2.style.setProperty('--x', h2Range.interpolate([-100, 0]));

      const minIndex = 0;
      const maxIndex = mainServices.length;
      const index = Math.floor(fullRange.interpolate([0, mainServices.length + 1], false));
      swiperRef.current.swiper.slideTo(Math.min(Math.max(index, minIndex), maxIndex));

      const rightLink = getRightLink(ref.current, interfaceLinks.types);
      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }
    };

    onScroll();

    // TODO: Add window resize handler
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <section style={{ '--count': mainServices.length }} className="services-section" ref={ref}>
      <div className="services-section__anchor" id="services-section" />

      <div className="services-section__sticky">
        <div className="services-section__title">
          <h2>
            Complete <br /> Real Estate <br /> Services
          </h2>
        </div>

        <Swiper
          className="services-section__list services-section__list--desktop"
          allowTouchMove={false}
          direction={'vertical'}
          slidesPerView={1}
          spaceBetween={0}
          ref={swiperRef}
          speed={500}
        >
          <SwiperSlide className="swiper-slide--empty" />
          {mainServices.map((service) => (
            <SwiperSlide key={service.id}>
              <Link to="/services" className="services-section__item">
                <img className="services-section__item__preview" src={service.preview} />
                <div className="services-section__item__title">
                  <h3 className="fill">{service.title}</h3>
                  <h3 className="outline">{service.title}</h3>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="services-section__list services-section__list--mobile">
          <Swiper slidesPerView={1} spaceBetween={10}>
            {mainServices.map((service) => (
              <SwiperSlide key={service.id}>
                <Link to="/services" className="services-section__item">
                  <img className="services-section__item__preview" src={service.preview} />
                  <div className="services-section__item__title">
                    <h3 className="fill">{service.title}</h3>
                    <h3 className="outline">{service.title}</h3>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Services;
