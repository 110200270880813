export const currenciesList = ['AED', 'USD', 'EUR'];

export const initialCurrency = currenciesList[0];

export const initialMin = 0;

/* 200 миллионов */
export const initialMax = 2 * Math.pow(10, 8);

/* 10 тысяч шагов */
export const stepsCount = Math.pow(10, 4);
