const interfaceLinks = {
  home: {
    text: 'Home page',
    href: '/',
    target: '_self',
    arrow: 'left'
  },
  whoWeAre: {
    text: 'Who we are',
    href: '#about-section',
    target: '_self',
    arrow: 'down'
  },
  services: {
    text: 'Services',
    href: '#services-section',
    target: '_self',
    arrow: 'down'
  },
  types: {
    text: 'Property types',
    href: '#types-section',
    target: '_self',
    arrow: 'down'
  },
  news: {
    text: 'News',
    href: '#news-section',
    target: '_self',
    arrow: 'down'
  },
  form: {
    text: 'Catalog',
    href: '#form-section',
    target: '_self',
    arrow: 'down'
  },
  contactUs: {
    text: 'Contact us',
    href: '/#contacts-section',
    target: '_self',
    arrow: 'down'
  },
  upside: {
    text: 'Upside',
    href: '#about-section',
    target: '_self',
    arrow: 'up'
  },
  terms: {
    text: 'Terms of Use',
    href: '/terms',
    target: '_blank',
    arrow: 'right'
  },
  privacy: {
    text: 'Privacy Policy',
    href: '/privacy',
    target: '_blank',
    arrow: 'right'
  },
  whatsapp: {
    text: 'WhatsApp',
    href: 'whatsapp',
    target: '_blank',
    arrow: 'right'
  },
  telegram: {
    text: 'Telegram',
    href: 'telegram',
    target: '_blank',
    arrow: 'right'
  },
  rights: {
    text: 'ZILA GREEN REAL ESTATE LLC',
    href: '',
    target: '_self',
    arrow: 'right'
  }
};

export const getRightLink = (element, link) => {
  const top = element?.getBoundingClientRect().top;
  const halfWindowHeight = window.innerHeight / 2;

  if (top > -element?.offsetHeight && top < halfWindowHeight) {
    return link;
  }
};

export default interfaceLinks;
