import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { useEffect, useRef, useState } from 'react';
import interfaceStore from 'store/interfaceStore';
import propertyTypes from 'lib/propertyTypes';
import { Link } from 'react-router-dom';

const typesAnimationSteps = {
  IN: 'in',
  IDLE: 'idle',
  OUT: 'out'
};

const Types = () => {
  const [typesAnimationStep, setTypesAnimationStep] = useState('');
  const [activeTypeId, setActiveTypeId] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      const y = ref.current?.getBoundingClientRect().y;
      const halfWindowHeight = window.innerHeight / 2;

      if (y > halfWindowHeight) {
        setTypesAnimationStep(typesAnimationSteps.IN);
      } else if (y < -halfWindowHeight) {
        setTypesAnimationStep(typesAnimationSteps.OUT);
      } else {
        setTypesAnimationStep(typesAnimationSteps.IDLE);
      }

      const rightLink = getRightLink(ref.current, interfaceLinks.news);
      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }
    };

    onScroll();

    // TODO: Add window resize handler
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <section className="types-section" id="types-section" ref={ref}>
      <div className="types-section__sticky">
        <div
          className="types-section__list"
          onMouseLeave={() => setActiveTypeId(null)}
          style={{ '--count': propertyTypes.length }}
        >
          {propertyTypes.map((type, index) => {
            const active = type.id === activeTypeId;
            const small = activeTypeId !== null && !active;
            let className = `types-section__item types-section__item--${type.id} types-section__item--${typesAnimationStep}`;

            if (active) className += ' types-section__item--active';
            if (small) className += ' types-section__item--small';

            return (
              <div
                onMouseEnter={() => setActiveTypeId(type.id)}
                className={className}
                key={type.id}
                style={{ '--index': index }}
              >
                <span className="types-section__item__number">{`0${type.id}`}</span>
                <span className="types-section__item__name">{type.name}</span>

                <img className="types-section__item__preview" src={type.preview} />
                <img
                  className="types-section__item__preview types-section__item__preview--mobile"
                  src={type.previewMobile}
                />

                <img className="types-section__item__image" src={type.previewFull} />

                <div className="types-section__item__corner types-section__item__corner--tr" />
                <div className="types-section__item__corner types-section__item__corner--br" />
                <div className="types-section__item__corner types-section__item__corner--bl" />

                <Link className="types-section__item__link" to={`/property-types?id=${type.id}`} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Types;
