import contactsStore from 'store/contactsStore';
import { redirect } from 'react-router-dom';

const loader = async () => {
  try {
    const data = await contactsStore.loadData();
    location.replace(data.privacy_policy.url);
    return true;
  } catch (e) {
    redirect('/');
  }
};

export default {
  path: '/privacy',
  children: [
    {
      index: true,
      element: () => <></>,
      loader
    }
  ]
};
