import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { scrollTo } from 'utils/helpers';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  const [oldPathname, setOldPathname] = useState('FIRST_RENDER');

  useEffect(() => {
    const isFirstRender = oldPathname === 'FIRST_RENDER';
    const isSamePage = pathname === oldPathname;

    const y = hash ? document.querySelector(hash)?.offsetTop || 0 : 0;

    scrollTo(y, !isFirstRender && hash && isSamePage);
    setOldPathname(pathname);
  }, [pathname, hash]);

  return null;
};

export default ScrollToTop;
