import { makeAutoObservable } from 'mobx';
import { API_URL } from 'config';

class ContactsStore {
  data = {
    address: 'Emaar Business Park \n Office 109, Building 4 \n Dubai, UAE',
    phone: '+971 56 161 9333',
    mail: 'info@zila.ae',
    map: 'https://www.google.com/maps/place/ZILA+REAL+ESTATE/@25.0958963,55.1673757,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f6b0024a6d411:0x6a520485a991d725!8m2!3d25.0958963!4d55.1673757!16s%2Fg%2F11vm07yvds?entry=ttu&g_ep=EgoyMDI0MTAyMS4xIKXMDSoASAFQAw%3D%3D'
  };

  constructor() {
    makeAutoObservable(this);
  }

  setData(data) {
    this.data = data;
  }

  get trimTel() {
    return this.data.phone.replaceAll(' ', '');
  }

  get whatsapp() {
    return 'https://wa.me/' + this.trimTel;
  }

  get telegram() {
    return 'https://t.me/' + this.trimTel;
  }

  async loadData() {
    const url = `${API_URL}/contact?populate=*`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to load contact data');
    }

    const result = await response.json();

    this.setData(result.data);

    return result.data;
  }
}

export default new ContactsStore();
