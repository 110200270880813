import interfaceLinks, { getRightLink } from 'store/interfaceLinks';
import { dpx, tpx, mpx, scrollProgress } from 'utils/helpers';
import { useEffect, useRef, useState } from 'react';
import interfaceStore from 'store/interfaceStore';
import { Link } from 'react-router-dom';

import fullscreenIcon from 'assets/img/about/fullscreen-button.svg';
import preview from 'assets/img/about/preview.webp';
import aboutVideo from 'assets/video/about.mp4';

const About = () => {
  const ref = useRef(null);

  const [fullscreenButtonState, setFullscreenButtonState] = useState(false);

  useEffect(() => {
    const video = document.querySelector('.about-section__video');
    const text = document.querySelector('.about-section__text');

    const textOpacityRange = scrollProgress(ref.current, [0.5, 0.9]);
    const fullRange = scrollProgress(ref.current, [0.0, 1.0]);

    const videoSteps = {
      1: {
        range: scrollProgress(ref.current, [0.0, 1.0]),
        tabletRange: scrollProgress(ref.current, [0.0, 0.5]),
        mobileRange: scrollProgress(ref.current, [0.0, 0.5]),

        width: () => [dpx(280), dpx(1426)],
        x: () => [dpx(-68), 0],
        y: () => [dpx(-76), 0],

        intermediateWidth: () => [dpx(220), window.innerWidth - dpx(110)],
        intermediateX: () => [dpx(-50), 0],
        intermediateY: () => [dpx(-70), 0],

        tabletWidth: () => [tpx(105), tpx(450)],
        tabletX: () => [tpx(-20), 0],
        tabletY: () => [tpx(-30), 0],

        mobileWidth: () => [mpx(105), window.innerWidth - mpx(40)],
        mobileX: () => [mpx(62), 0],
        mobileY: () => [mpx(-44), 0]
      },
      2: {
        range: scrollProgress(ref.current, [1.25, 2.25]),
        tabletRange: scrollProgress(ref.current, [0.75, 1.5]),
        mobileRange: scrollProgress(ref.current, [0.75, 1.5]),

        width: () => [dpx(1426), dpx(1426)],
        x: () => [0, window.innerWidth / 2 + dpx(1426)],
        y: () => [0, 0],

        intermediateWidth: () => [window.innerWidth - dpx(110), window.innerWidth - dpx(110)],
        intermediateX: () => [0, window.innerWidth + dpx(110)],
        intermediateY: () => [0, 0],

        tabletWidth: () => [tpx(450), tpx(450)],
        tabletX: () => [0, window.innerWidth],
        tabletY: () => [0, 0],

        mobileWidth: () => [window.innerWidth - mpx(40), window.innerWidth - mpx(40)],
        mobileX: () => [0, window.innerWidth + mpx(40)],
        mobileY: () => [0, 0]
      }
    };

    const onScroll = () => {
      const totalProgress = fullRange.getProgress(false);

      video.style.position = totalProgress > 0 ? 'fixed' : 'absolute';
      text.style.opacity = textOpacityRange.interpolate([1.0, 0.0]);

      const currentVideoStep = totalProgress < 1.2 ? videoSteps[1] : videoSteps[2];

      const currentTabletVideoStep = totalProgress < 0.8 ? videoSteps[1] : videoSteps[2];

      const currentMobileVideoStep = totalProgress < 0.8 ? videoSteps[1] : videoSteps[2];

      const fullScreenButtonVisible = totalProgress >= 0.5;
      setFullscreenButtonState(fullScreenButtonVisible);

      const { range, width, x, y, ...currentIntermediateVideoStep } = currentVideoStep;
      const { intermediateWidth, intermediateX, intermediateY } = currentIntermediateVideoStep;
      const { mobileRange, mobileX, mobileY, mobileWidth } = currentMobileVideoStep;
      const { tabletRange, tabletX, tabletY, tabletWidth } = currentTabletVideoStep;

      if (width()) {
        // Desktop
        video.style.setProperty('--width', range.interpolate(width()));
      }
      video.style.setProperty('--x', range.interpolate(x()));
      video.style.setProperty('--y', range.interpolate(y()));

      // intermediate
      if (intermediateWidth()) {
        video.style.setProperty('--intermediate-width', range.interpolate(intermediateWidth()));
      }
      video.style.setProperty('--intermediate-x', range.interpolate(intermediateX()));
      video.style.setProperty('--intermediate-y', range.interpolate(intermediateY()));

      // tablet
      if (mobileWidth()) {
        video.style.setProperty('--tablet-width', tabletRange.interpolate(tabletWidth()));
      }
      video.style.setProperty('--tablet-x', tabletRange.interpolate(tabletX()));
      video.style.setProperty('--tablet-y', tabletRange.interpolate(tabletY()));

      // mobile
      if (mobileWidth()) {
        video.style.setProperty('--mobile-width', mobileRange.interpolate(mobileWidth()));
      }
      video.style.setProperty('--mobile-x', mobileRange.interpolate(mobileX()));
      video.style.setProperty('--mobile-y', mobileRange.interpolate(mobileY()));

      // Links
      const rightLink = getRightLink(ref.current, interfaceLinks.services);
      if (rightLink) {
        interfaceStore.setRightLink(rightLink);
      }
    };

    onScroll();

    // TODO: Add window resize handler
    window.addEventListener('resize', onScroll);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <section className="about-section" id="about-section" ref={ref}>
      <div className="about-section__sticky">
        <h2 className="about-section__text">
          We are <span className="about-section__text-space" /> wherever
          <br className="br--desktop" /> our clients’ properties are
        </h2>
        <Link to="/about" className="about-section__video">
          <video
            src={aboutVideo}
            poster={preview}
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
          />

          <button
            className={`about-section__fullscreen-button ${
              fullscreenButtonState ? 'about-section__fullscreen-button--visible' : ''
            }`}
          >
            <img src={fullscreenIcon} alt="fullscreen-button" />
          </button>
        </Link>
      </div>
    </section>
  );
};

export default About;
