import PropTypes from 'prop-types';

const FormBedrooms = ({ value, onInput }) => {
  const onClick = (number) => onInput('bedrooms', number);

  return (
    <div className="form-bedrooms">
      <h2 className="form-bedrooms__title">Bedrooms</h2>
      <div className="form-bedrooms__buttons">
        {new Array(10).fill('').map((_, index) => (
          <button
            type="button"
            key={index + 1}
            className={value === index + 1 ? 'active' : ''}
            onClick={() => onClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

FormBedrooms.propTypes = {
  onInput: PropTypes.func,
  value: PropTypes.number
};

export default FormBedrooms;
