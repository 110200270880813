import preview6 from 'assets/img/propertyTypes/preview-6.png';
import preview5 from 'assets/img/propertyTypes/preview-5.png';
import preview4 from 'assets/img/propertyTypes/preview-4.png';
import preview3 from 'assets/img/propertyTypes/preview-3.png';
import preview2 from 'assets/img/propertyTypes/preview-2.png';
import preview1 from 'assets/img/propertyTypes/preview-1.png';

import previewFull6 from 'assets/img/propertyTypes/preview-full-6.png';
import previewFull5 from 'assets/img/propertyTypes/preview-full-5.png';
import previewFull4 from 'assets/img/propertyTypes/preview-full-4.png';
import previewFull3 from 'assets/img/propertyTypes/preview-full-3.png';
import previewFull2 from 'assets/img/propertyTypes/preview-full-2.png';
import previewFull1 from 'assets/img/propertyTypes/preview-full-1.png';

import previewMobile6 from 'assets/img/propertyTypes/preview-mobile-6.png';
import previewMobile5 from 'assets/img/propertyTypes/preview-mobile-5.png';
import previewMobile4 from 'assets/img/propertyTypes/preview-mobile-4.png';
import previewMobile3 from 'assets/img/propertyTypes/preview-mobile-3.png';
import previewMobile2 from 'assets/img/propertyTypes/preview-mobile-2.png';
import previewMobile1 from 'assets/img/propertyTypes/preview-mobile-1.png';

import residentialApartments1 from 'assets/img/propertyTypes/residential-apartments/residential-apartments-1.webp';
import residentialApartments2 from 'assets/img/propertyTypes/residential-apartments/residential-apartments-2.webp';
import residentialApartments3 from 'assets/img/propertyTypes/residential-apartments/residential-apartments-3.webp';
import residentialApartments4 from 'assets/img/propertyTypes/residential-apartments/residential-apartments-4.webp';

import townhouses1 from 'assets/img/propertyTypes/townhouses/townhouses-1.webp';
import townhouses2 from 'assets/img/propertyTypes/townhouses/townhouses-2.webp';
import townhouses3 from 'assets/img/propertyTypes/townhouses/townhouses-3.webp';
import townhouses4 from 'assets/img/propertyTypes/townhouses/townhouses-4.webp';

import villas1 from 'assets/img/propertyTypes/villas/villas-1.webp';
import villas2 from 'assets/img/propertyTypes/villas/villas-2.webp';
import villas3 from 'assets/img/propertyTypes/villas/villas-3.webp';
import villas4 from 'assets/img/propertyTypes/villas/villas-4.webp';

import hotelApartments1 from 'assets/img/propertyTypes/hotel-apartments/hotel-apartments-1.webp';
import hotelApartments2 from 'assets/img/propertyTypes/hotel-apartments/hotel-apartments-2.webp';
import hotelApartments3 from 'assets/img/propertyTypes/hotel-apartments/hotel-apartments-3.webp';
import hotelApartments4 from 'assets/img/propertyTypes/hotel-apartments/hotel-apartments-4.webp';

import commercialProperty1 from 'assets/img/propertyTypes/commercial-property/commercial-property-1.webp';
import commercialProperty2 from 'assets/img/propertyTypes/commercial-property/commercial-property-2.webp';
import commercialProperty3 from 'assets/img/propertyTypes/commercial-property/commercial-property-3.webp';
import commercialProperty4 from 'assets/img/propertyTypes/commercial-property/commercial-property-4.webp';

import land1 from 'assets/img/propertyTypes/land/land-1.webp';
import land2 from 'assets/img/propertyTypes/land/land-2.webp';
import land3 from 'assets/img/propertyTypes/land/land-3.webp';
import land4 from 'assets/img/propertyTypes/land/land-4.webp';

export default [
  {
    id: 1,
    name: 'Residential Apartments',
    description:
      'Residential apartments are the most common and popular type of real estate in Dubai, making up 80% of the entire primary real estate market (newly constructed properties).\n\nTypically, residential apartments are purchased through interest-free payment plans directly from the developer. When paying the initial down payment, the Dubai land tax, which is 4% of the property value, is also paid upfront.',
    images: [
      residentialApartments1,
      residentialApartments2,
      residentialApartments3,
      residentialApartments4
    ],
    previewMobile: previewMobile1,
    previewFull: previewFull1,
    preview: preview1
  },
  {
    id: 2,
    name: 'Townhouses',
    description:
      'Townhouses are a fairly popular type of residential property in Dubai. This format usually involves the dense development of a master-planned community with identical townhouses, typically grouped into blocks of 2-3-4 units.\n\nA distinctive feature of townhouses, compared to villas, is the lack of private land surrounding the property—only shared community land is available.\n\nDespite this, townhouses are in high demand in Dubai and are considered an attractive option for investment.',
    images: [townhouses1, townhouses2, townhouses3, townhouses4],
    previewMobile: previewMobile2,
    previewFull: previewFull2,
    preview: preview2
  },
  {
    id: 3,
    name: 'Villas',
    description:
      'Villas are the most expensive and, at the same time, the most sought-after type of luxury residential real estate in Dubai.\n\nSince the COVID-19 pandemic, there has been a consistently high demand for villas, which remains strong to this day. There is a notable shortage of land available for villa development in Dubai, making projects from reputable developers in prime locations highly attractive to investors.\n\nOne of the key features of villas is that they always come with a small private plot of landscaped land.',
    images: [villas1, villas2, villas3, villas4],
    previewMobile: previewMobile3,
    previewFull: previewFull3,
    preview: preview3
  },
  {
    id: 4,
    name: 'Hotel Apartments',
    description:
      'Hotel apartments are a less common type of property, but still offered by some developers in the Dubai real estate market.\n\nIt’s important to understand that hotel apartments fall under the category of commercial real estate. Therefore, in addition to the 4% land tax, a 5% VAT is also applied at the time of purchase.\n\nCaution is crucial when purchasing hotel apartments. If the developer does not include a guaranteed income clause in the contract, this can turn into a highly risky investment',
    images: [hotelApartments1, hotelApartments2, hotelApartments3, hotelApartments4],
    previewMobile: previewMobile4,
    previewFull: previewFull4,
    preview: preview4
  },
  {
    id: 5,
    name: 'Commercial Property',
    description:
      'In Dubai, commercial property refers to any real estate intended for generating direct commercial profit. Given the broad scope of this category, it is divided into several subcategories:\n\n• Retail commercial property\n• Office space\n• Hotel real estate\n• Restaurants / food service establishments\n• Warehouse property\n• General-purpose real estate\n\nIt is important to note that when purchasing commercial property in Dubai, in addition to the 4% land tax, a 5% VAT is also applied to the property’s purchase price.',
    images: [commercialProperty1, commercialProperty2, commercialProperty3, commercialProperty4],
    previewMobile: previewMobile5,
    previewFull: previewFull5,
    preview: preview5
  },
  {
    id: 6,
    name: 'Land',
    description:
      'It is important to note that land in Dubai is divided into two major categories:\n\n• Leasehold (long-term lease)\n• Freehold (ownership)\n\nAs foreign investors, we are only interested in Freehold land, as we can purchase and build exclusively on this category of land.\n\nWhen purchasing a plot of land, for example, for the construction of a hotel or office building, it is crucial to carefully review all restrictions imposed on the land by the Dubai Land Department. These restrictions may pertain to building height/floors, the intended use of the building, the possibility of obtaining an alcohol license, and other factors.\n\nAs with the purchase of commercial property, a 5% VAT is applied to the purchase of land, in addition to the land tax.',
    images: [land1, land2, land3, land4],
    previewMobile: previewMobile6,
    previewFull: previewFull6,
    preview: preview6
  }
];
