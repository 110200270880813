import interfaceLinks from './interfaceLinks';
import { makeAutoObservable } from 'mobx';

const interfaceColors = {
  BLACK: 'black',
  WHITE: 'white'
};

class InterfaceStore {
  currentColor = interfaceColors.BLACK;

  middleLinks = [interfaceLinks.contactUs];

  rightLink = null;

  leftLinks = [interfaceLinks.whatsapp, interfaceLinks.telegram];

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentColor(color) {
    this.currentColor = color;
  }

  setMiddleLinks(links) {
    this.middleLinks = links;
  }

  setRightLink(link) {
    this.rightLink = link;
  }

  setLeftLinks(links) {
    this.leftLinks = links;
  }
}

export { interfaceColors };

export default new InterfaceStore();
