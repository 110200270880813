import interfaceStore, { interfaceColors } from 'store/interfaceStore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import contactsStore from 'store/contactsStore';
import { scrollTo } from 'utils/helpers';
import newsStore from 'store/newsStore';
import { observer } from 'mobx-react';

import NewsItem from './NewsItem';

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import { ReactComponent as LogoFill } from 'assets/svg/zila/fill.svg';
import { ReactComponent as LogoMin } from 'assets/svg/logo-min.svg';

const Interface = observer(() => {
  const ref = useRef(null);

  const [menuState, setMenuState] = useState(false);

  const { pathname, hash } = useLocation();
  const isMainPage = pathname === '/';

  const menuLinks = [
    {
      text: 'Home Page',
      href: '/'
    },
    {
      text: 'About us',
      href: '/about'
    },
    {
      text: 'Services',
      href: '/services'
    },
    {
      text: 'Property Types',
      href: '/property-types?id=1'
    },
    {
      text: 'Contact us',
      href: `${isMainPage ? '' : '/'}#contacts-section`
    }
  ];

  const openMenu = () => setMenuState(true);

  const closeMenu = () => setMenuState(false);

  const onLinkClick = (event) => {
    closeMenu();

    const url = new URL(event.currentTarget.href);
    if (url.hash && url.hash === hash) {
      const element = document.querySelector(url.hash);
      if (element) {
        scrollTo(element.offsetTop);
      }
    }
  };

  const getClassName = (position) => {
    let className = `interface interface--${position}`;
    if (menuState) {
      className += ` interface--menu-open interface--${interfaceColors.WHITE}`;
    } else {
      className += ` interface--${interfaceStore.currentColor}`;
    }
    return className;
  };

  useEffect(() => {
    ref.current.addEventListener('wheel', (event) => {
      event.stopPropagation();
    });
  }, []);

  return (
    <div ref={ref}>
      <div className={getClassName('top')}>
        <div className="interface__background" />

        <Link to={'/#main-section'} className="interface__logo-min" onClick={onLinkClick}>
          <LogoMin />
        </Link>
        <Link to={'/#main-section'} className="interface__logo-fill" onClick={onLinkClick}>
          <LogoFill />
        </Link>

        <button className="interface__burger">
          <div
            className="interface__burger-wrapper"
            onClick={menuState ? closeMenu : openMenu}
            type="button"
          >
            <div className="interface__burger-bar" />
            <div className="interface__burger-bar" />
            <div className="interface__burger-bar" />
            <span className="interface__burger-text">{menuState ? 'Close' : 'Menu'}</span>
          </div>
        </button>

        <div className="interface__menu">
          <div className="interface__menu-links">
            {menuLinks.map((link) => (
              <Link
                className="interface__menu-links-link"
                to={link.href}
                key={link.href}
                onClick={onLinkClick}
              >
                {link.text}
              </Link>
            ))}
          </div>
          <div className="interface__menu-news">
            <div className="interface__menu-news__title">
              News
              <span>{newsStore.news.length}</span>
            </div>
            {newsStore.news.map((item) => (
              <Link key={item.id} to="/#news-section" onClick={onLinkClick}>
                <NewsItem data={item} />
              </Link>
            ))}
          </div>
          <div className="interface__menu-footer">
            <a
              className="interface__menu-footer-link"
              target="_blank"
              href={contactsStore.telegram}
              rel="noreferrer"
            >
              Telegram
            </a>
            <a
              className="interface__menu-footer-link"
              target="_blank"
              href="/terms"
              rel="noreferrer"
            >
              Terms Of Use
            </a>
            <a
              className="interface__menu-footer-link"
              target="_blank"
              href={contactsStore.whatsapp}
              rel="noreferrer"
            >
              Whatsapp
            </a>
            <a
              className="interface__menu-footer-link"
              target="_blank"
              href="/privacy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>

      <div className={getClassName('bottom')}>
        <Link to={'/#main-section'} className="interface__logo-min" onClick={onLinkClick}>
          <LogoMin />
        </Link>

        <div className="interface__left-link-wrapper">
          <div className="interface__left-link">
            {interfaceStore.leftLinks.map((link, index) => (
              <Link
                key={`${link.text}-${link.href}`}
                to={link.href ? contactsStore[link.href] : ''}
                style={{ pointerEvents: link.href ? 'auto' : 'none' }}
                target={link.target}
              >
                <div className="circle">
                  <span>{link.href ? index + 1 : 'C'}</span>
                </div>
                <p>{link.text}</p>
              </Link>
            ))}
          </div>
        </div>

        <div className="interface__middle-link-wrapper">
          <div className="interface__middle-link">
            {interfaceStore.middleLinks.map((link) => (
              <Link
                key={`${link.text}-${link.href}`}
                to={link.href}
                target={link.target}
                onClick={onLinkClick}
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>

        {interfaceStore.rightLink && (
          <div className="interface__right-link-wrapper">
            <Link
              to={interfaceStore.rightLink.href}
              className="interface__right-link"
              onClick={onLinkClick}
            >
              <p>{interfaceStore.rightLink.text}</p>
              <ArrowDown className={interfaceStore.rightLink.arrow} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});

export default Interface;
