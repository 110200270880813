import PropTypes from 'prop-types';

export const newnessTypes = ['primary', 'secondary'];

const FormNewness = ({ value, onInput }) => {
  const setPrimary = () => onInput('newness', newnessTypes[0]);
  const setSecondary = () => onInput('newness', newnessTypes[1]);

  return (
    <div className="form-newness">
      <button
        type="button"
        onClick={setPrimary}
        className={value === newnessTypes[0] ? 'active' : ''}
      >
        Primary
      </button>
      <button
        type="button"
        onClick={setSecondary}
        className={value === newnessTypes[1] ? 'active' : ''}
      >
        Secondary
      </button>
    </div>
  );
};

FormNewness.propTypes = {
  onInput: PropTypes.func,
  value: PropTypes.string
};

export default FormNewness;
